<template>
    <v-dialog v-model="dialog" width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                dense
                dark
                min-width="300"
                max-width="400"
                :color="color"
                class="my-2"
                v-bind="attrs"
                v-on="on"
            >
                {{ label }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 primary lighten-5">
                {{ label }}
            </v-card-title>

            <v-card-text class="mt-10">
                <slot name="content"></slot>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="text" text @click="closeDialog()">
                    {{ $t(`profile.dialog.closeBtn`) }}
                </v-btn>
                <slot name="action"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "UpdateDialog",

    props: {
        label: {
            required: true,
            type: String,
        },

        close: {
            required: true,
            type: Boolean,
        },
        color: {
            required: false,
            type: String,
            default: "primary darken-1",
        },
    },

    data() {
        return {
            dialog: false,
        };
    },

    methods: {
        closeDialog() {
            this.dialog = false;
        },
    },

    watch: {
        close(val) {
            if (val) this.dialog = false;
        },
        dialog(val) {
            if (val == false) this.$emit("update:open", false);
        },
    },
};
</script>
